// ivew 树状图 加图标
<template>
  <Tree :data="treeData"
        :show-checkbox="showCheckbox"
        :render="customTree"
        @on-select-change="onSelectChange"
        @on-check-change="onCheckChange"></Tree>
</template>

<script>
export default {
  components: {},
  props: {
    keyName: {
      type: String,
      default: 'name'
    },
    data: Array,
    showCheckbox: Boolean
  },
  data () {
    return {
      treeData: []
    };
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.resetTreeData()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.resetTreeData()
    },
    getTreeIcon (type) {
      let str = ''
      switch (type) {
        case 'depart':
          str = 'icon-gongsi'
          break;
        case 'user':
          str = 'icon-jiaose'
          break;
        case 'emp':
          str = 'icon-duoren'
          break;
        case 'car':
          str = 'icon-che'
          break;
        case 'facl':
          str = 'icon-sheshi'
          break;
        case '1':
          str = 'icon-quyu1'
          break;
        case '2':
          str = 'icon-quyu1'
          break;
        case '3':
          str = 'icon-quyu1'
          break;
        case 'group':
          str = 'icon-sheshi'
          break;
        default:
          break;
      }
      return str
    },
    customTree (h, { root, node, data }) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '100%'
        }
      }, [
        h('span', [
          h('Icon', {
            props: {
              custom: 'i-icon ' + this.getTreeIcon(data.type),
              size: '18',
              color: '#25bb96'
            },
            style: {
              marginRight: '8px'
            }
          }),
          h('span', {
            style: {
              marginRight: '8px',
              color: (data.type === 'car' || data.type === 'user') ? '#0D9DFF' : ''
            }
          }, data[this.keyName])
        ])
      ]);
    },
    onSelectChange (section, data) {
      this.$emit('on-select-change', section, data)
    },
    onCheckChange (section, data) {
      this.$emit('on-check-change', section, data)
    },
    resetTreeData () {
      if (!this.data) return this.treeData = []
      let newData = JSON.parse(JSON.stringify(this.data))
      this.treeData = newData
    }
  },
}
</script>

<style lang='scss' scoped>

</style>