<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Input v-model="systemName"
                   placeholder="系统名称"
                   class="m-r-10"
                   style="width:160px" />
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/systemConfig/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/systemConfig/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/systemConfig/add'])"
                    @click.stop="systemConfigVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="table">
          <Table ref="table"
                 :width="tableWidth"
                 :height="tableHeight"
                 :columns="columns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button type="text"
                      v-hasPermi="['/admin/systemConfig/edit']"
                      style="color:#25bb96;padding:0 5px"
                      @click.stop="onClickEditSystemConfig(row)">修改</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <SystemConfigModal v-model="systemConfigVisible"
                       :dataId.sync="systemConfigId"
                       :width="drawerWidth"
                       @onClickConfirm="getList" @onChange='onChangClearRowStyle'></SystemConfigModal>
  </div>
</template>

<script>
import SystemConfigModal from '@/components/product/admin/modal/systemSetting/SystemConfigModal'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    SystemConfigModal
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '初始化配置列表'
        }
      ],
      systemName: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columns: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '公司名称',
          key: 'comName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '版权所有',
          key: 'copyright',
          tooltip: true,
          align: 'center'
        }, {
          title: '系统负责人',
          key: 'sysManager',
          tooltip: true,
          align: 'center'
        },
        {
          title: '系统负责人电话',
          key: 'managerPhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '地图中心点',
          key: 'mapFocusName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '签约日期',
          key: 'createTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定单位',
          key: 'bindOrgId',
          tooltip: true,
          align: 'center'
        },
        {
          title: '过期时效(天)',
          key: 'timeoutDay',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 后台系统配置
      systemConfigVisible: false,
      systemConfigId: '',
      drawerWidth: 0,
    };
  },
  watch: {
    // currentTabIndex () {
    //   this.resetSearchData()
    //   this.onClickSearch()
    // },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.getList()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 排序
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.systemName = ''
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    getList () {
      this.getSystemConfigList()
    },
    getSystemConfigList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
      }
      this.$http.getSystemConfigList(params).then(res => {
        // console.log(res);
        this.tableLoading = false
        if (res.code == 200) {
          this.totalPage = res.result.total
          this.data = res.result.inspectionlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改
    onClickEditSystemConfig (row) {
      this.selectVisitedArr.push(row)
      this.systemConfigId = row.sysId
      this.systemConfigVisible = true
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
