// 一张网 下拉框展开
<template>
<!-- 弹框动画 -->
  <CollapseTransition>
    <div :class="position"
         style="z-index:900"
         :style="customStyle"
         v-if="visible"
         v-click-outside="handlerClose">
      <slot></slot>
    </div>
  </CollapseTransition>
</template>

<script>
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import vClickOutside from 'v-click-outside'
export default {
  components: { CollapseTransition },
  directives: { clickOutside: vClickOutside.directive },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    position: {
      type: String,
      default: 'fixed'
    },
    customStyle: Object,
    disabled: Boolean,
  },
  data () {
    return {

    };
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    handlerClose () {
      if (!this.disabled) {
        this.$emit('change', false)
      }
    }
  },
}
</script>

<style lang='scss' scoped>
</style>