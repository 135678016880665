// 搜索框下拉树
<template>
  <!-- 下拉 trigger点击弹出， -->
  <Dropdown ref="drop"
            trigger="click"
            placement="bottom-start"
            class="treeSelect">
    <div @click="showDropdown($event)">
      <!-- 传入插槽就显示 -->
      <template v-if="$slots.default">
        <slot></slot>
      </template>
      <!-- 没有插槽显示，默认输入框 -->
      <template v-else>
        <!-- mouseHover 移入移除 显隐清除图标 -->
        <div class="relative"
             @mouseenter="mouseHover = true"
             @mouseleave="mouseHover = false">
          <Input v-model="p_value"
                 :placeholder="placeholder"
                 readonly
                 :disabled="disabled"
                 class="treeSelect-input" placeholderColor="#fff" :class="inpStyle">
          </Input>
          <!-- 清空图标 用input默认的清空图标不会清空多选选中的数据 -->
          <Icon type="ios-close-circle"
                class="ivu-select-arrow treeSelect-clear"
                v-show="isShowClear"
                @click.native.stop="onClear"></Icon>
        </div>
      </template>
    </div>
    <div class="treeSelect-dropdown"
         slot="list">
      <Input ref="searchInput"
             v-model="searchValue"
             :placeholder="searchPlaceholder"
             search
             @on-change="onChangeSearchValue"
             v-if="search" />
      <div class="treeSelect-tree">
        <MyTree ref="myTree"
                :data="treeData"
                :keyName="keyName"
                :show-checkbox="checked"
                @on-select-change="onSelectChange"
                @on-check-change="onCheckChange"></MyTree>
      </div>
      <!-- 多选显示确定按钮 -->
      <div class="treeSelect-bottom"
           v-if="checked">
        <Button type="primary"
                @click.stop="onClickSave">确定</Button>
      </div>
    </div>
  </Dropdown>
</template>

<script>
import MyCollapse from '@/components/common/MyCollapse';
import MyTree from '@/components/common/MyTree';
export default {
  components: { MyCollapse, MyTree },
  props: {
    value: String,
    placeholder: String,
    // 清除图标
    clearable: Boolean,
    keyName: {
      type: String,
      default: 'name'
    },
    data: Array,
    search: Boolean,
    searchPlaceholder: String,
    checked: Boolean,
	inpStyle:String,
    disabled: null
  },
  data () {
    return {
      p_value: '',
      mouseHover: false,
      treeData: [],
      selectArr: [],
      searchValue: '',
    };
  },
  watch: {
    value (newVal) {
      this.p_value = newVal
    },
    data: {
      deep: true,
      handler () {
        this.resetTreeData()
      }
    }
  },
  computed: {
    // p_value  私有的value(input)
    isShowClear () {
      return this.clearable && this.p_value && this.mouseHover && !this.disabled
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.resetTreeData()
    },
    showDropdown (e) {
      if (this.disabled) return e.stopPropagation()
    },
    onClear () {
      this.resetTreeData()
      if (this.checked) {
        this.onClickSave()
      } else {
        this.onSelectChange(null, { id: '', name: '' })
      }
    },
    onSelectChange (section, data) {
      this.$emit('onSelectChange', data)
      !this.checked && this.$refs.drop.handleClose()
    },
    onCheckChange (section, data) {
      this.selectArr = section
    },
    onClickSave () {
      this.$emit('onCheckChange', this.selectArr)
      this.$refs.drop.handleClose()
    },
    // 树input修改
    onChangeSearchValue () {
      let newData = JSON.parse(JSON.stringify(this.data))
      if (!this.searchValue) {
        this.treeData = newData
      } else {
        this.treeData = this.treeSearch(newData)
      }
    },
    // 递归  查询
    treeSearch (arr) {
      return arr.filter(item => {
        if (item[this.keyName].indexOf(this.searchValue) !== -1) {
          // console.log(item[this.keyName]);
          return item
        } else {
          if (item.children) {
            item.children = this.treeSearch(item.children)
            if (item.children.length > 0) {
              return item
            }
          }
        }
      });
    },
    // 拿到树的值后，复制一份，因为筛选后会改变改，所以说不能对原来的值操作
    resetTreeData () {
      if (!this.data) return this.treeData = []
      // 深拷贝
      let newData = JSON.parse(JSON.stringify(this.data))
      this.treeData = newData
      this.$refs.myTree.resetTreeData()
      if (this.search) this.searchValue = ''
      if (this.checked) this.selectArr = []
    }
  },
}
</script>

<style lang='scss'>
.treeSelect {
  .ivu-select-dropdown {
    padding: 0;
    width: 300px;
  }
  .treeSelect-input {
    cursor: pointer;
  }
  .treeSelect-clear {
    cursor: pointer;
  }
  .treeSelect-dropdown {
    background-color: #fff;
    overflow: auto;
    .treeSelect-tree {
      padding: 0 10px;
      height: 260px;
      overflow: auto;
    }
    .treeSelect-bottom {
      height: 42px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #e6e6e6;
    }
  }
}
/* ::v-deep{
	.inpStyle{
		input{
			height: 36px;
			font-size: 14px;
			color: #FFFFFF;
			background: rgba(0, 186, 255, 0.35);
			border: 1px solid #00BAFF;
		}
	}
} */

</style>