<template>
  <Drawer :title="dataId ? '修改' + title : '新增' + title"
          :mask="false"
          closable
          :width="width"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="drawer">
      <div class="modal-cont">
        <ul class="form-ul">
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              系统名称：
            </p>
            <Input v-model="systemName"
                   placeholder="系统名称"
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              首页名称：
            </p>
            <Input v-model="indexName"
                   placeholder="首页名称"
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              地图中心点：
            </p>
            <Input v-model="address"
                   readonly
                   placeholder="地图中心点">
            <Icon custom="i-icon icon-weizhishi"
                  size="20"
                  color="#25bb96"
                  class="cursor-pointer"
                  slot="suffix"
                  @click="onClickAddress" />
            </Input>
          </li>
          <!-- <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              文件上传路径：
            </p>
            <Input v-model="upLoadSrc"
                   placeholder="文件上传路径"
                   maxlength="30"
                   v-stringLimit
                   disabled></Input>
          </li> -->
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              视频中台地址：
            </p>
            <Input v-model="videoSrc"
                   placeholder="视频中台地址"
                   maxlength="30"></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              视频中台AppId：
            </p>
            <Input v-model="videoCode"
                   placeholder="视频中台AppId"
                   maxlength="30"></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              一张网轮循时长(车与人)：
            </p>
            <Input v-model="mainRefreshTime"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="half"></Input>
            <span class="li-unit half">秒（不能低于30秒）</span>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              超时退出机制：
            </p>
            <Input v-model="loginTimeout"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   clearable
                   class="half"></Input>
            <span class="li-unit">分钟</span>
            <div class="half m-l-20">
              <Checkbox v-model="loginTimeoutEnable"
                        class="form-ul-li-checkbox">关闭</Checkbox>
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              公司名称：
            </p>
            <Input v-model="companyName"
                   placeholder="公司名称"
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              版权所有：
            </p>
            <Input v-model="copyright"
                   placeholder=""
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              技术支持：
            </p>
            <Input v-model="support"
                   placeholder=""
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              系统Logo：
            </p>
            <div class="logo half">
              <div class="item">
                <UploadFile @uploadSuccess="uploadLoginLogo"
                            :disabled="loginLogo"
                            class="upload">
                  <el-image :src="loginLogo"
                            fit="cover"
                            :preview-src-list="[loginLogo]"
                            class="w-full h-full"
                            v-show="loginLogo">
                  </el-image>
                  <Icon type="md-close-circle"
                        size="24"
                        color="#fa5357"
                        class="file-img-deleteIcon"
                        @click.stop="onClickDeleteLoginLogo"
                        v-show="loginLogo" />
                  <div class="choose-img"
                       v-show="!loginLogo">
                    <Icon custom="i-icon icon-tianjia1"
                          size="40" />
                  </div>
                </UploadFile>
                <p class="tip">登录logo</p>
                <p class="tip">60*60</p>
              </div>
              <!-- <div class="item">
                <UploadFile @uploadSuccess="uploadPageHeader"
                            :disabled="pageHeader"
                            class="upload">
                  <el-image :src="pageHeader"
                            fit="cover"
                            :preview-src-list="[pageHeader]"
                            class="w-full h-full"
                            v-show="pageHeader">
                  </el-image>
                  <Icon type="md-close-circle"
                        size="24"
                        color="#fa5357"
                        class="file-img-deleteIcon"
                        @click.stop="onClickDeletePageHeader"
                        v-show="pageHeader" />
                  <div class="choose-img"
                       v-show="!pageHeader">
                    <Icon custom="i-icon icon-tianjia1"
                          size="30" />
                  </div>
                </UploadFile>
                <p class="tip">页面头部</p>
                <p class="tip">40*40</p>
              </div>
              <div class="item">
                <UploadFile @uploadSuccess="uploadPageTitle"
                            :disabled="pageTitle"
                            class="upload">
                  <el-image :src="pageTitle"
                            fit="cover"
                            :preview-src-list="[pageTitle]"
                            class="w-full h-full"
                            v-show="pageTitle">
                  </el-image>
                  <Icon type="md-close-circle"
                        size="24"
                        color="#fa5357"
                        class="file-img-deleteIcon"
                        @click.stop="onClickDeletePageTitle"
                        v-show="pageTitle" />
                  <div class="choose-img"
                       v-show="!pageTitle">
                    <Icon custom="i-icon icon-tianjia1"
                          size="14" />
                  </div>
                </UploadFile>
                <p class="tip">网页标题</p>
                <p class="tip">18*18</p>
              </div> -->
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title"></p>
            <span class="tip">最多允许上传一张图片，图片大小不得超过10K</span>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              登录背景：
            </p>
            <div class="login-bg">
              <UploadFile @uploadSuccess="uploadLoginBgImg"
                          :disabled="loginBgImg"
                          class="upload">
                <el-image :src="loginBgImg"
                          fit="cover"
                          :preview-src-list="[loginBgImg]"
                          class="w-full h-full"
                          v-show="loginBgImg">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteLoginBgImg"
                      v-show="loginBgImg" />
                <div class="choose-img login-bg-img"
                     v-show="!loginBgImg">
                  <Icon custom="i-icon icon-tianjia1"
                        size="30" />
                </div>
              </UploadFile>
              <p class="tip">1920*600</p>
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title"></p>
            <span class="tip">最多允许上传一张图片，图片大小不得超过1M</span>
          <li class="form-ul-li">
            <p class="li-title">
              平台联系电话：
            </p>
            <Input v-model="phone"
                   placeholder=""
                   maxlength="30"
                   v-stringLimit
                   clearable></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              过期时限：
            </p>
            <Input v-model="tokenTimeout"
                   placeholder=""
                   maxlength="30"
                   v-intLimit
                   class="half"></Input>
            <span class="li-unit half">天</span>
          </li>
          <li class="form-ul-li app-title">
            <p class="li-title">APP设置</p>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              APP二维码：
            </p>
            <div class="code">
              <div class="item">
                <UploadFile @uploadSuccess="uploadAndroidCode"
                            :disabled="androidCode"
                            class="upload">
                  <el-image :src="androidCode"
                            fit="cover"
                            :preview-src-list="[androidCode]"
                            class="w-full h-full"
                            v-show="androidCode">
                  </el-image>
                  <Icon type="md-close-circle"
                        size="24"
                        color="#fa5357"
                        class="file-img-deleteIcon"
                        @click.stop="onClickDeleteAndroidCode"
                        v-show="androidCode" />
                  <div class="choose-img"
                       v-show="!androidCode">
                    <Icon custom="i-icon icon-tianjia1"
                          size="30" />
                  </div>
                </UploadFile>
                <p class="tip">Android 160*160</p>
              </div>
              <div class="item">
                <UploadFile @uploadSuccess="uploadIosCode"
                            :disabled="iosCode"
                            class="upload">
                  <el-image :src="iosCode"
                            fit="cover"
                            :preview-src-list="[iosCode]"
                            class="w-full h-full"
                            v-show="iosCode">
                  </el-image>
                  <Icon type="md-close-circle"
                        size="24"
                        color="#fa5357"
                        class="file-img-deleteIcon"
                        @click.stop="onClickDeleteIosCode"
                        v-show="iosCode" />
                  <div class="choose-img"
                       v-show="!iosCode">
                    <Icon custom="i-icon icon-tianjia1"
                          size="30" />
                  </div>
                </UploadFile>
                <p class="tip">IOS 160*160</p>
              </div>
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title"></p>
            <span class="tip">最多允许上传一张图片，图片大小不得超过1M</span>
          </li>
          <li class="form-ul-li">
            <p class="li-title"></p>
            <span class="warn">生成二维码是，选择尺寸160*160，不要进行压缩放大</span>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              <span class="li-must">*</span>
              登录页名称：
            </p>
            <Input v-model="loginName"
                   placeholder="登录页名称"
                   maxlength="30"
                   v-stringLimit></Input>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              登录页图片：
            </p>
            <div class="login-img">
              <UploadFile @uploadSuccess="uploadLoginImg"
                          :disabled="loginImg"
                          class="upload">
                <el-image :src="loginImg"
                          fit="cover"
                          :preview-src-list="[loginImg]"
                          class="w-full h-full"
                          v-show="loginImg">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteLoginImg()"
                      v-show="loginImg" />
                <div class="choose-img"
                     v-show="!loginImg">
                  <Icon custom="i-icon icon-tianjia1"
                        size="30" />
                </div>
              </UploadFile>
              <p class="tip">154*154</p>
              <p class="tip">最多允许上传一张图片，图片大小不得超过50K</p>
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title">
              轮播图：
            </p>
            <div class="slideshow-box flex flex-wrap">
              <div v-for="item,index in bannerArr"
                   :key="index"
                   class="slideshow-img m-r-10 m-b-10">
                <el-image :src="item"
                          fit="cover"
                          :preview-src-list="bannerArr"
                          class="w-full h-full">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteBanner(index)" />
              </div>
              <UploadFile @uploadSuccess="uploadBanner"
                          multiple>
                <div class="choose-img">
                  <Icon custom="i-icon icon-tianjia1"
                        size="30" />
                </div>
              </UploadFile>
            </div>
          </li>
          <li class="form-ul-li">
            <p class="li-title"></p>
            <Button type="primary"
                    :loading="loading"
                    @click.stop="onClickConfirm">确定</Button>
          </li>
        </ul>
      </div>
    </div>
    <!-- 选择地点 -->
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeAddress" />
  </Drawer>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import PositionModal from '@/components/product/main/template/PositionModal'
import UploadFile from '@/components/common/UploadFile';
export default {
  components: {
    MyTreeSelect,
    PositionModal,
    UploadFile
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '后台系统配置'
    },
    width: Number,
    dataId: String
  },
  data () {
    return {
      loading: false,
      systemName: '',
      indexName: '',
      // upLoadSrc: '',
      lng: '',
      lat: '',
      address: '',
      videoSrc: '',
      videoCode: '',
      mainRefreshTime: '5',
      loginTimeout: '',
      loginTimeoutEnable: '',
      companyName: '',
      copyright: '',
      support: '',
      loginLogo: '',
      // pageHeader: '',
      // pageTitle: '',
      loginBgImg: '',
      phone: '',
      tokenTimeout: '',
      androidCode: '',
      iosCode: '',
      loginName: '',
      loginImg: '',
      companyNameData: [],
      bannerArr: [],
      // 选择地址
      positionVisible: false,
    };
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    },
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    getDetail () {
      this.$store.dispatch('getSystemConfigDetail').then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        let lnglatArr = res.lnglat.split(',')
        this.lng = lnglatArr[0]
        this.lat = lnglatArr[1]
        this.loginTimeoutEnable = res.loginTimeoutEnable == 1 ? true : false
        if (res.bannerArr) {
          this.bannerArr = res.bannerArr.split(',').filter(item => item)
        } else {
          this.bannerArr = []
        }
      })
    },
    // 点击选择地址
    onClickAddress () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeAddress (e) {
      this.address = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 上传登录Logo
    uploadLoginLogo (arr) {
      if (arr.length > 0) {
        this.loginLogo = arr[0].url
      }
    },
    // 删除登录Logo
    onClickDeleteLoginLogo () {
      this.loginLogo = ''
    },
    // 上传页面头部图片
    uploadPageHeader (arr) {
      if (arr.length > 0) {
        this.pageHeader = arr[0].url
      }
    },
    // 删除页面头部图片
    onClickDeletePageHeader () {
      this.pageHeader = ''
    },
    // 上传网页标题图片
    uploadPageTitle (arr) {
      if (arr.length > 0) {
        this.pageTitle = arr[0].url
      }
    },
    // 删除网页标题图片
    onClickDeletePageTitle () {
      this.pageTitle = ''
    },
    // 上传网页标题图片
    uploadLoginBgImg (arr) {
      if (arr.length > 0) {
        this.loginBgImg = arr[0].url
      }
    },
    // 删除网页标题图片
    onClickDeleteLoginBgImg () {
      this.loginBgImg = ''
    },
    // 上传安卓二维码
    uploadAndroidCode (arr) {
      if (arr.length > 0) {
        this.androidCode = arr[0].url
      }
    },
    // 删除安卓二维码
    onClickDeleteAndroidCode () {
      this.androidCode = ''
    },
    // 上传安卓二维码
    uploadIosCode (arr) {
      if (arr.length > 0) {
        this.iosCode = arr[0].url
      }
    },
    // 删除安卓二维码
    onClickDeleteIosCode () {
      this.iosCode = ''
    },
    // 上传登录页图片
    uploadLoginImg (arr) {
      if (arr.length > 0) {
        this.loginImg = arr[0].url
      }
    },
    // 删除登录页图片
    onClickDeleteLoginImg () {
      this.loginImg = ''
    },
    // 上传轮播图图片
    uploadBanner (arr) {
      if (arr.length > 0) {
        this.bannerArr = this.bannerArr.concat(arr.map(item => item.url))
      }
    },
    // 删除轮播图图片
    onClickDeleteBanner (index) {
      this.bannerArr.splice(index, 1)
    },
    onClickConfirm () {
      if (!this.systemName) return this.$Message.info('请输入系统名称');
      if (!this.indexName) return this.$Message.info('请输入首页名称');
      if (!this.address) return this.$Message.info('请选择地图中心点');
      // if (!this.upLoadSrc) return this.$Message.info('请输入文件上传路径');
      if (!this.videoSrc) return this.$Message.info('请输入视频中心地址');
      if (!this.videoCode) return this.$Message.info('请输入视频中心code码');
      if (!this.mainRefreshTime) return this.$Message.info('请输入一张网轮循时长(车与人)');
      if (parseInt(this.mainRefreshTime) < 30) return this.$Message.info('一张网轮循时长(车与人)不能低于30秒');
      if (!this.companyName) return this.$Message.info('请输入公司名称');
      if (!this.copyright) return this.$Message.info('请输入版权所有');
      if (!this.support) return this.$Message.info('请输入技术支持');
      if (!this.loginLogo) return this.$Message.info('请上传登录logo');
      // if (!this.pageHeader) return this.$Message.info('请上传页面头部图片');
      // if (!this.pageTitle) return this.$Message.info('请上传网页标题图片');
      if (!this.loginBgImg) return this.$Message.info('请上传登录背景图片');
      if (!this.loginName) return this.$Message.info('请输入登录页名称');
      let params = {
        systemName: this.systemName,
        indexName: this.indexName,
        lnglat: this.lng + ',' + this.lat,
        address: this.address,
        videoSrc: this.videoSrc,
        videoCode: this.videoCode,
        mainRefreshTime: this.mainRefreshTime,
        loginTimeout: this.loginTimeout,
        loginTimeoutEnable: this.loginTimeoutEnable,
        companyName: this.companyName,
        copyright: this.copyright,
        support: this.support,
        loginLogo: this.loginLogo,
        loginBgImg: this.loginBgImg,
        phone: this.phone,
        tokenTimeout: this.tokenTimeout,
        androidCode: this.androidCode,
        iosCode: this.iosCode,
        loginName: this.loginName,
        loginImg: this.loginImg,
      }
      if (this.bannerArr.length) {
        params.bannerArr = this.bannerArr.join()
      }
      // if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditSystemConfig', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
        this.$store.dispatch('updateGridTreeList', true)
      }).catch(err => {
        this.loading = false
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.systemName = ''
      this.indexName = ''
      this.address = ''
      this.lng = ''
      this.lat = ''
      // this.upLoadSrc = ''
      this.videoSrc = ''
      this.videoCode = ''
      this.mainRefreshTime = 5
      // this.facilityOrGrid = '5'
      this.loginTimeout = ''
      this.loginTimeoutEnable = false
      this.companyName = ''
      this.copyright = ''
      this.support = ''
      this.loginLogo = ''
      // this.pageHeader = ''
      // this.pageTitle = ''
      this.loginBgImg = ''
      // this.principal = ''
      this.phone = ''
      // this.depart = ''
      // this.departCheckbox = false
      this.tokenTimeout = ''
      this.androidCode = ''
      this.iosCode = ''
      this.loginName = ''
      this.loginImg = ''
      this.remark = ''
    }
  },
}
</script>

<style lang='scss' scoped>
::v-deep {
  .ivu-drawer {
    top: 60px;
    bottom: 0;
    .ivu-drawer-body {
      padding: 0;
    }
  }
}
.modal-cont {
  max-height: none;
  margin-top: 15px;
  .form-ul {
    width: 45%;
    margin: 0 auto;
    .form-ul-li {
      .li-title {
        min-width: 210px;
      }
    }
  }
}
.drawer {
  // height: calc(100% - 64px);
  margin-bottom: 75px;
  .logo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .item {
      .upload,
      .choose-img {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
      .upload {
        position: relative;
      }
      p {
        padding: 10px 0;
      }
    }
    .item:nth-child(2) .choose-img,
    .item:nth-child(2) .upload {
      width: 40px;
      height: 40px;
    }
    .item:nth-child(3) .choose-img,
    .item:nth-child(3) .upload {
      width: 18px;
      height: 18px;
    }
  }
  .login-bg {
    .login-bg-img,
    .upload {
      width: 160px;
      height: 90px;
      cursor: pointer;
    }
    .upload {
      position: relative;
    }
    p {
      padding: 10px 0;
    }
  }
  .file-img-deleteIcon {
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    z-index: 1;
  }
  .choose-img {
    background: #f0f3f6;
    border: 1px solid #d2d4d5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .tip {
    font-size: 12px;
    color: #999;
  }
  .warn {
    color: red;
    font-size: 12px;
  }
  .app-title {
    p {
      text-align: right;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .code {
    display: flex;
    .item {
      margin-right: 20px;
      .choose-img,
      .upload {
        width: 120px;
        height: 120px;
        cursor: pointer;
      }
      .upload {
        position: relative;
      }
      p {
        padding: 10px 0;
      }
    }
  }
  .login-img {
    .choose-img,
    .upload {
      width: 120px;
      height: 120px;
      cursor: pointer;
    }
    .upload {
      position: relative;
    }
    p {
      padding: 10px 0;
    }
  }
  .slideshow-box {
    .choose-img,
    .upload,
    .slideshow-img {
      width: 120px;
      height: 120px;
      cursor: pointer;
    }
    .upload {
      position: relative;
    }
    .slideshow-img {
      position: relative;
    }
  }
}
</style>