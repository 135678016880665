// 上传文件
<template>
  <div @click="handleClick">
    <!-- type-file h5上传文件方法  -->
    <input ref="input"
           type="file"
           :accept="accept"
           :multiple="multiple"
           :webkitdirectory="webkitdirectory"
           @change="handleChange"
           class="input-style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // 限制类型
    accept: {
      type: String,
      default: 'image/*'
    },
    // 多选
    multiple: Boolean,
    webkitdirectory: Boolean,
    disabled: null,
    uploadpath: String,
  },
  data () {
    return {
      resultArr: []
    }
  },
  methods: {
    handleClick () {
      // 点击外面div调用input的点击方法，因为input无法修改样式
      if (this.disabled) return
      this.$refs.input.click()
    },
    // 上传完回调
    handleChange (e) {
      // files是file对象的数组
      const files = e.target.files
      if (!files) return
      this.upload(files)
      this.$refs.input.value = null
    },
    upload (files) {
      let filelist = Array.from(files)
      this.$emit('uploadstart')
      console.log(filelist);
      this.resultArr = []
      filelist.forEach(item => {
        if (this.getFileType(item.name) == 1) {
          this.uploadImg(item, filelist)
        } else if (this.getFileType(item.name) == 2) {
          this.uploadFile(item, filelist)
        }
      })
    },
    // 上传图片
    uploadImg (item, filelist) {
      let reader = new FileReader()
      reader.readAsDataURL(item)
      reader.onload = () => {
        // 给后端传，对接口
        let fileResult = reader.result.replace(/data.+?;base64,/, "")
        let fileName = item.name
        let params = {
          base64: fileResult,
          fileName
        }
        this.$http.uploadImg(params).then(res => {
          console.log(res);
          if (res.code === 200) {
            let result = {
              url: res.result,
              fileName
            }
            this.resultArr.push(result)
            // 上传的文件长度等于文件的长度
            if (this.resultArr.length === filelist.length) {
              // 抛出去
              this.$emit('uploadSuccess', this.resultArr)
            }
          } else {
            this.$Message.info(res.message)
          }
        }).catch(err => {
          this.$Message.info('上传失败')
        })
      }
    },
    // 上传文件
    uploadFile (item, filelist) {
      let reader = new FileReader()
      reader.readAsDataURL(item)
      reader.onload = () => {
        // 给后端传，对接口
        let fileName = item.name
        let params = new FormData();
        params.append('file', item)
        this.$http.uploadFile(params).then(res => {
          console.log(res);
          if (res.code === 200) {
            let result = {
              url: res.result,
              fileName
            }
            this.resultArr.push(result)
            // 上传的文件长度等于文件的长度
            if (this.resultArr.length === filelist.length) {
              // 抛出去
              this.$emit('uploadSuccess', this.resultArr)
            }
          } else {
            this.$Message.info(res.message)
          }
        }).catch(err => {
          console.log(err);
          this.$Message.info('上传失败')
        })
      }
    },
    // 判断文件类型
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
