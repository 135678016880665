<template>
  <Modal :value="visible"
         title="位置"
         width="800"
         class="position_dialog"
         @on-visible-change="onChangeVisible">
    <div class="dialog_content">
      <AutoComplete ref="search"
                    v-model="searchValue"
                    placeholder=""
                    @on-search="onSearch"
                    @on-select="onSelect"
                    class="dialog_content_input">
        <Option v-for="item in searchData"
                :value="item.hotPointID"
                :key="item.hotPointID">{{ item.name }}</Option>
      </AutoComplete>
      <!-- 天地图div -->
      <div class="mapDiv"
           ref="positionMapDiv" />
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '@/utils/tMap'
export default {
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    lng: [Number, String],
    lat: [Number, String]
  },
  data () {
    return {
      loading: false,
      map: null,  // 天地图实例
      zoom: 0, // 初始地图缩放等级
      location: {},
      address: '',
      searchValue: '',
      searchData: [],
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    // this.init()
  },
  methods: {
    init () {
      if (!this.map) return this.createMap()
      if (this.lng && this.lat) {
        this.location = new T.LngLat(this.lng, this.lat)
        this.map.centerAndZoom(this.location, this.zoom)
        this.createMarker(this.location)
      }
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.zoom = 15
        this.map = new T.Map(this.$refs.positionMapDiv)
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.addEventListener('click', this.onClickMap)
        if (this.lng && this.lat) {
          this.location = new T.LngLat(this.lng, this.lat)
          this.map.centerAndZoom(this.location, this.zoom)
          this.createMarker(this.location)
        } else {
          this.map.centerAndZoom(lnglat, this.zoom)
        }
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败，请刷新浏览器')
      })
    },
    // 地图点击事件
    async onClickMap (e) {
      // 点击地区的经纬度
      this.location = e.lnglat
      // 先清空原先的覆盖物
      this.map.clearOverLays()
      this.createMarker(e.lnglat)
    },
    // 生成标记点
    async createMarker (location) {
      //创建图片对象
      let icon = new T.Icon({
        iconUrl: require('../../../../assets/main/guidepost.png'),
        iconSize: new T.Point(24, 32),
        iconAnchor: new T.Point(10, 30)
      })
      let lnglat = new T.LngLat(location.lng, location.lat)
      let marker = new T.Marker(lnglat, { icon: icon })
      this.address = await this.getAddress(lnglat)
      let content = `<div>${this.address}</div>`
      let label = new T.Label({ text: content, position: lnglat, offset: new T.Point(0, -40) })
      this.map.addOverLay(marker)
      this.map.addOverLay(label)
    },
    // 逆地理编码
    async getAddress (lnglat) {
      const geocode = new T.Geocoder()
      return new Promise((resolve) => {
        geocode.getLocation(lnglat, (e) => {
          if (e.getStatus() === 0) {
            const addressComponent = e.getAddressComponent()
            resolve(addressComponent.address)
          } else {
            resolve(lnglat.lng + ',' + lnglat.lat)
          }
        })
      })
    },
    // 搜索地址
    onSearch (val) {
      this.searchValue = val
      let config = {
        pageCapacity: 10,
        onSearchComplete: this.onSearchAddress
      }
      let LocalSearch = new T.LocalSearch(this.map, config)
      LocalSearch.search(this.searchValue, 1)
    },
    onSearchAddress (e) {
      this.searchData = e.getPois()
    },
    onSelect (val) {
      let obj = this.searchData.find(item => item.hotPointID === val)
      this.$nextTick(() => {
        this.searchValue = obj.name
      })
      let arr = obj.lonlat.split(' ')
      this.map.panTo(new T.LngLat(arr[0], arr[1]), 17)
    },
    onClickConfirm () {
      this.$emit('onClickConfirm', {
        address: this.address,
        lng: this.location.lng,
        lat: this.location.lat
      })
      this.$emit('onChange', false)
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.map.clearOverLays()
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.position_dialog {
  .dialog_content {
    position: relative;

    .dialog_content_input {
      width: 40%;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 1000;
    }

    .mapDiv {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
